<template>
  <div class="feature_container">
    <div class="ops">
      <div @click="goBack" class="back_btn">返回</div>
      <div>
        <el-select v-model="city" @change="citySelected">
          <el-option label="--所有市州--" value="all"></el-option>
          <el-option
            v-for="item in cityList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-select v-model="district" @change="districtSelected">
          <el-option label="--所有区县--" value="all"></el-option>
          <el-option
            v-for="item in districtList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
    </div>
    <div class="title">{{ title }}</div>
    <div class="intro">{{ intro }}</div>
    <!-- <div class="title">民族特色分类标题</div> -->
    <div class="card_list">
      <el-card class="card" v-for="item in newsList" :key="item.id">
        <div
          class="img_content"
          :style="{ backgroundImage: 'url(' + item.cover + ')' }"
          @click="goDetail(item.id)"
        >
          <!-- <img :src="item.cover" alt=""> -->
        </div>
        <div class="text_content" @click="goDetail(item.id)">
          <div class="title">{{ item.title }}</div>
          <div class="dec">{{ item.describes }}</div>
        </div>
      </el-card>
    </div>
    <div class="pagination_container">
      <el-pagination
        @current-change="handleCurrentChange"
        background
        :current-page.sync="page"
        :page-size="limit"
        layout="prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <el-backtop style="width: 1rem; height: 2rem" :right="10" :bottom="50">
      <div style="height: 100%; width: 100%">
        <img style="width: 100%; height: 100%" src="@/assets/img/toTop.png" />
        <div style="text-align: center" class="goTop">回顶部</div>
      </div>
    </el-backtop>
  </div>
</template>

<script>
import cityData from '@/utils/sichuan.json'
export default {
  name: 'village',
  data() {
    return {
      cityList: cityData,
      districtList: [],
      city: 'all',
      district: 'all',
      page: 1,
      limit: 15,
      total: 38,
      newsList: [],
      categoryId: 0,
      title: '',
      intro: ''
    }
  },
  methods: {
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`)
      this.page = val
      this.getCategory()
    },
    // 跳转至详情页
    goDetail(val) {
      this.$router.push({ path: '/detail', query: { id: val } })
    },
    // 获取栏目列表
    getCategory() {
      let url =
        '/api/web_index/feature?categoryId=' +
        this.categoryId +
        '&page=' +
        this.page +
        '&limit=' +
        this.limit
      if (this.city !== 'all') {
        let city = this.city
        if (this.district !== 'all') {
          city += ',' + this.district
        }
        url += '&city=' + encodeURIComponent(city)
      }
      this.$axios.get(url).then((res) => {
        console.log('46--->', res.data)
        const a = res.data
        // this.newsList = a.documentsPage.records
        this.title = a.categoryName
        this.intro = a.describes
        this.newsList = a.documentsPage.records
        this.total = a.documentsPage.total
        document.body.scrollTop = document.documentElement.scrollTop = 750
      })
    },
    goBack() {
      this.$router.push({ path: '/' })
    },
    citySelected(val) {
      const query = { categoryId: this.categoryId }
      if (val !== 'all') {
        query.city = val
      }
      this.$router.push({
        path: '/feature',
        query: query
      })
    },
    districtSelected(val) {
      const query = { categoryId: this.categoryId }
      if (this.city !== 'all') {
        query.city = this.city
      }
      if (val !== 'all') {
        query.city += ',' + val
      }
      this.$router.push({
        path: '/feature',
        query: query
      })
    },
    handleCityQuery(cityParam) {
      if (cityParam) {
        const parArr = cityParam.split(',')
        const cityVal = parArr[0]
        const districtVal = parArr[1]
        const city = this.cityList.find((item) => item.value === cityVal)
        if (city == null) {
          this.city = 'all'
          this.district = 'all'
          this.districtList = []
        } else {
          this.city = cityVal
          this.districtList = city.children || []
          const district = this.districtList.find(
            (item) => item.value === districtVal
          )
          if (district == null) {
            this.district = 'all'
          } else {
            this.district = districtVal
          }
        }
      } else {
        this.city = 'all'
        this.district = 'all'
      }
    }
  },
  mounted() {
    this.categoryId = this.$route.query.categoryId
    this.handleCityQuery(this.$route.query.city)
    this.getCategory()
  },
  watch: {
    '$route.query.categoryId'() {
      this.page = 1
      this.categoryId = this.$route.query.categoryId
      this.getCategory()
    },
    '$route.query.city'() {
      this.handleCityQuery(this.$route.query.city)
      this.getCategory()
    }
  }
}
</script>

<style lang="scss">
@import 'index.scss';
.feature_container {
  .el-backtop {
    background-color: transparent;
    box-shadow: none;
  }
}
@media screen and (min-width: 1600px) {
  .img_content {
    height: 5rem;
  }
}
</style>
<style>
@media screen and (max-width: 600px) {
  .feature_container .title {
    font-size: 0.4rem !important;
    margin-bottom: 0.4rem !important;
  }
  .feature_container .card_list .card {
    width: 100%;
    margin-right: 0;
  }
}
</style>
